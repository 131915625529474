body {
    font-family: Montserrat, sans-serif;
    color: rgb(26, 27, 31);
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    padding: 24px;
}

.footer-wrap {
    display: flex;
    justify-content: right;
    gap: 10px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.logo-footer {
    width: 50px;
    vertical-align: middle
}

.intro-header {
    display: flex;
    height: 140px;
    margin-bottom: 40px;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white
}

.heading {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 40px;
    text-transform: none;
    text-align: center;
}

.heading-2 {
    font-size: 30px;
}

.heading-3 {
    font-size: 20px;
    line-height: 40px;
    opacity: 0.6;
    margin:0
}

.motto-wrap {
    width: 100%;
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: auto;
    text-align: center;
    border: 1px solid rgb(238, 238, 238);
    box-sizing: border-box;
    padding: 20px;
}

.heading-small {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
    text-transform: none;
}

.container {
    width: 100%;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
}

.about-story-wrap {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 20px;
    background-color: #E9E9E8;
    position: relative
}

.paragraph-light {
    opacity: 0.6;
}

h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
}

.label.cc-light {
    opacity: 0.6;
}

.section-heading-wrap {
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: center;
}

.section {
    margin-right: 30px;
    margin-left: 30px;
}

.our-services-grid {
    margin-bottom: 120px;
    gap: 60px 80px;
    grid-template-areas: ". " ". ";
    grid-template-columns: 1fr;
}

.w-layout-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 60px;
    width:100%;
    margin:0 auto;
    box-sizing: border-box;
}

.service-image {
    width: 128px
}

.container {
    width: 100%;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
}

.contact-form-grid {
    margin-bottom: 100px;
    align-items: start;
    gap: 30px 80px;
    border: 1px solid rgb(238, 238, 238);
    ;
    padding: 20px;
}

.contact-form-wrap {
    padding: 45px 50px 50px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(238, 238, 238);
}

.contact-form-heading-wrap {
    margin-bottom: 40px;
}

.contact-heading {
    margin-top: 0px;
    margin-bottom: 15px;
    font-weight: 400;
}

.logo {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px
}

.divider {
    height: 1px;
    background-color: rgb(238, 238, 238);
    margin-top: 50px;
    margin-bottom: 20px;
}

h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
}


.section-heading-wrap {
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: center;
}

.section {
    margin-right: 30px;
    margin-left: 30px;
}

.service-block {
    text-align: center;
}

.service-image {
    width: 128px
}

.center {
    text-align: center;
}

.vacancy-link {
    color: black;
    text-underline-offset: 4px;
}

.vacancy-link:hover {
    text-decoration: underline;
}

.rustLogo-container{
    align-self: center;
}

.rustLogo {
    width: 100px;
}

.small-vacancy-text {
    margin: 0;
    text-align: left;
}

.vacancy-block {
    width: 100%;
    margin: 0 auto;
    border: 1px solid rgb(238, 238, 238);
    box-sizing: border-box;
    padding: 20px;
    background-color: rgb(238, 238, 238);
 
}

.vacancy-wrap {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #E9E9E8;
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: space-around;
    box-sizing: border-box;
}

.vacancy-wrap.vacancy {
    background-color: white;
    border: 1px solid rgb(238, 238, 238);
    border-bottom: none;
    margin-bottom: 0;
    box-sizing: border-box;
    width:100%;
}

.emailLink {
    text-decoration: none;
    font-family: monospace;
}

.emailLink:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
}

ul{
    padding-left: 12px;
}

.offer-paragraph {
    font-size: 20px;
}

.paragraph-light.about {
    text-align: left;
}

.paragraph-bigger {
    font-size: 20px;
    line-height: 34px;
}

.company-name{
    font-weight: 500
}


@media screen and (min-width: 800px) {
    .small-vacancy-text {
        padding: 0 30px;
    }
    .vacancy-name {
        padding-left: 30px
    }
}

@media screen and (min-width: 600px) {
    .service-block {
        text-align: left;
    }
    .footer-wrap {
        margin: 0 auto;
        width: 80%
    }
    .w-layout-grid {
        grid-template-columns: 1fr 1fr;
        width:80%;
    }

    .contact-heading {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .vacancy-link {
        text-underline-offset: 8px;
    }
    .about-story-wrap {
        margin: 0 auto;
        padding: 40px;
        width: 80%
    }
    .vacancy-block {
        padding: 40px;
        width:80%;
    }
    .contact-form-grid {
        padding: 40px;
    }

    .motto-wrap {
        width: 80%;

    }

    .vacancy-wrap {
        padding: 40px;
        width:80%
    }

    .vacancy-wrap.vacancy{
        width:80%
    }
}
