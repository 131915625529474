.navigation-item {
    padding-top: 9px;
    padding-bottom: 9px;
    opacity: 0.6;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
}

.menu-horizontal{
    display: none
}

@media screen and (min-width: 800px) {
    
    .menu-horizontal{
        display: flex;
        padding: 0;
    }
    .navigation-item {
        padding:30px
    }
}
.w-nav-link.active {
    text-decoration: underline;
    text-underline-offset: 8px;
}


.w-nav-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #222222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}



.navigation {
    display: flex;
    align-items: center;
    background-color: transparent;
}


.navigation-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 0%;
    margin-bottom: 10px;
}


.w-nav-brand {
    position: relative;
    float:left
}

.logoNav{
    height:140px;

}

.logoNav{
cursor:pointer
    
}


.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}


@media (max-width: 768px) {

.logoNav{
    height:100px;
    
}
    .menu{
        display: flex;
        flex-direction: column;
    }    
    .w-nav-link {
        color:white
    }

    .navbar-container {
        display: block;
        position: relative;
        height: 120px;
      }
    
      .navbar-container input[type="checkbox"] {
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 38px;
        right: 20px;
        z-index: 5;
        opacity: 0;
      }
    
      .navbar-container .hamburger-lines {
        display: block;
        height: 24px;
        width: 35px;
        position: absolute;
        top: 38px;
        right: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    
      .navbar-container .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
      }
    
  
    .navbar-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }
  
    .navbar-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }
  
    .navbar-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }
  
    .navbar .menu-wrap{
      padding-top: 100px;
      background: black;
      height: 100vh;
      width:350px;
      max-width: 350px;
      display: none;
      padding-left: 50px;
      box-shadow: 5px 0px 10px 0px #aaa;
      margin-left:auto;
      position: absolute;
      top:120px;
      right:0;
      margin-top:0;
      box-sizing:border-box;
      z-index: 2;
    }


    .navbar-container input[type="checkbox"]:hover{
        cursor: pointer;
    }

    .navbar .menu-wrap.closed{
        display: none;
    }
  
  
    .navbar  .menu-wrap.open{
      display: block;
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(35deg);
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }
  
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-35deg);
    }
  

  }